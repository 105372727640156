export interface listType<T> {
    name: number | T
    title: string
}
export class List_tab {
    tab: listType<string>[] = [
        {
            name: 0,
            title: '全部'
        },
        {
            name: 1,
            title: '待发货'
        },
        {
            name: 2,
            title: '待收货'
        },
        {
            name: 3,
            title: '已完成'
        }
    ]
}

export interface AddressInit<T, K> {
    city: string
    createAt: string
    detail: string
    district: string
    id: number
    mobile: string
    name: string
    province: string
    updateAt: string
    user_id: number
}
export class AddressList {
    list: AddressInit<string, number>[] = []
}