interface OrderListDto<T, K> {
    courier_number: K
    createAt: T
    falg: K
    id: K
    order_number: T
    order_status: K
    updateAt: T
    user_id: K
    entity_sp: entitySpDto<T, K>
}

interface entitySpDto<L, P> {
    id: P
    uuid: L
    title: L
    content: L
    image: L
    before_integral: P
    now_integral: P
    stock: P
    falg: P
    is_vip: boolean
    is_kind: boolean
    sort: P | null
    createAt: L
    updateAt: L
}
export class OrderList {
    allList: OrderListDto<string, number>[] = []
}
